<template>
    <div class="top_div">
        <div class="top_div01">GamerCenter</div>
        <div v-if="true" class="top_div02">
            <i class="el-icon-more"></i>
        </div>
        <div v-if="false" class="top_div02" @click="toBindTwitter()">
            <img src="../../assets/images/twitter_logo.jpg"/>
            Bind Twitter
        </div>
    </div>
</template>

<script>
    import request from "../../tools/request";
    import toolUtils from "../../tools/toolUtils";

    export default {
        name: "Top",
        data() {
            return {
                userId: 0,
                userName: "USERNAME",
                isBinding: false,
            }
        },
        watch: {},
        created() {
            if (sessionStorage.getItem("userId")) {
                this.userId = sessionStorage.getItem("userId");
                sessionStorage.setItem("userId", this.userId);
            }
            if (sessionStorage.getItem("userName")) {
                this.userName = sessionStorage.getItem("userName");
                sessionStorage.setItem("userName", this.userName);
            }
        },
        mounted() {
            if (sessionStorage.getItem("userId")) {
                this.userId = sessionStorage.getItem("userId");
            }
            if (sessionStorage.getItem("userName")) {
                this.userName = sessionStorage.getItem("userName");
            }

            this.getUserInfoById();
            this.getVerifyBindingAndSignIn();
        },
        methods: {
            async getUserInfoById() {
                if (this.userId) {
                    await request.getUserInfoById(this.userId).then((res) => {
                        this.userName = res.data.user_name;
                        if (res.data.user_name) {
                            sessionStorage.setItem("userName", res.data.user_name);
                        }
                    }).catch((err) => {
                        this.$message.error(err.message);
                    });
                }
            },
            async getVerifyBindingAndSignIn() {
                if (this.userId) {
                    await request.getVerifyBindingAndSignIn(this.userId).then((res) => {
                        this.isBinding = res.data.isBinding;
                    }).catch((err) => {
                        this.$message.error(err.message);
                    })
                }
            },
            toBindTwitter() {
                if (this.userId) {
                    window.location.href = "https://task.mintagame.com/#/login?is_binding=true&user_id=" + this.userId;
                }
            },
            cutAccount(val) {
                return val.length > 8 ? toolUtils.cutAccount(val, 3) : val;
            }
        }
    }
</script>

<style lang="less">
    .top_div {
        width: 100%;
        height: 100px;
        background: black;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        .top_div01 {
            width: auto;
            height: 100%;
            margin-left: 50px;
            background: black;
            color: white;
            font-size: 24px;
            line-height: 100px;
            font-weight: 600;
            text-align: left;
        }
        .top_div02 {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: flex-end;

            margin-right: 50px;
            color: white;
            font-size: 20px;

            img {
                width: 35px;
                margin-top: -2px;
                cursor: pointer;
            }
        }
    }
</style>
